<template>
  <p>
    Verifying it's you...
  </p>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "Authenticate.vue",
  props: ["uid", "token"],
  computed: {
    ...mapGetters([
      'jwt',
      'jwtSubject',
      'jwtIssuer'
    ])
  },

  mounted() {

    if (this.token) {
      this.$store.dispatch("setJWT", this.token)
      //var decoded = jwt_decode(this.token);
      this.$router.push('/')
    }

  }

}
</script>

<style scoped>

</style>